<template>
  <el-menu :collapse-transition="true" :default-active="activeIndex" :router="true" @select="handleSelect"
           active-text-color="#1d3752" background-color="#fff" class="esb-header" menu-trigger="hover"
           mode="horizontal" text-color="#fff" style="min-width: 1290px;width: 100%;padding: 0 50px !important;">

    <el-dialog :visible.sync="clientsReportDialog" title="" top="3vh" width="1200px" custom-class="" append-to-body>
      <report-print-form v-if="clientsReportDialog" :title="reportTitle" @close="clientsReportDialog = false" :subTitle="dueDateLabel" :reportColumns="reportColumns" :reportData="reportData" :reportCellCustomClass="reportCellCustomClass"></report-print-form>
    </el-dialog>
    <!--<el-dropdown>
      <el-button type="plain" size="mini" class="border-0">
        File<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>Action 1</el-dropdown-item>
        <el-dropdown-item>Action 2</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>-->

<!--
    <el-dropdown>
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain">
        Data <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link :to="">Clients</router-link>
        </el-dropdown-item>


        <el-dropdown-item>
          <router-link :to="{ name: '', params: { listPage: 1}}">Labels</router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
-->

    <el-dropdown v-if="isAdmin">
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain" @click="openPage({ name: 'module->Accounting->Client->create', params: { listPage: 1}})">
        Data
      </el-button>
    </el-dropdown>

    <el-dropdown v-if="isAdmin">
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain" @click="openPage({ name: 'module->Accounting->Client->labels-page', params: { listPage: 1}})">
        Labels
      </el-button>
    </el-dropdown>

    <el-dropdown>
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain" @click="goToQuickView">
        Quick View
      </el-button>
    </el-dropdown>


    <el-dropdown v-if="isAdmin">
      <el-button class="border-0" size="mini" type="plain">
        Report<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link :to="{ name: 'module->Accounting->Report->tax-report'}">Tax Report</router-link>
        </el-dropdown-item>
        <!--<el-dropdown-item>
          <router-link :to="{ name: 'module->Accounting->Report->tax-report'}">Payroll Schedule</router-link>
        </el-dropdown-item>-->
        <el-dropdown-item>
          <router-link :to="{ name: 'module->Accounting->Report->control-report'}">Control Report</router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown v-if="!isAdmin">
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain" @click="openUserClientsReport">
        {{ userDisplayName }}'s Clients
      </el-button>
    </el-dropdown>

    <el-dropdown>
      <el-button class="border-0" style="padding-left: 0 !important;" size="mini" type="plain" @click="goToQuickNote">
        Quick Note
      </el-button>
    </el-dropdown>



    <el-dropdown v-if="$services['user'].isRoot() || isAdmin">
      <el-button class="border-0" size="mini" type="plain">
        Setup<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link :to="{ name: 'module->App->User->create-raw', params: { listPage: 1}}">Users</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link :to="{ name: 'module->App->UiColorSchema->select-ui-schema', params: { listPage: 1}}">System Color</router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown style="float:right">
      <el-button class="border-0" style="padding-right: 0 !important;" size="mini" type="plain">
        <i class="el-icon-user icon"></i> {{ $xBundle['CURRENT_USER_PROFILE'].display_name }}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <!--<el-dropdown-item>
          <i class="el-icon-more-outline icon"></i> User
        </el-dropdown-item>-->
        <el-dropdown-item>
          <div @click="$router.push({path: '/portal/User/logout'})" style="margin: 5px 10px">
            <i class="el-icon-switch-button icon" style="color: #f17214;"></i> Logout
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown style="float:right" v-if="$services['user'].isRoot() || isAdmin">
      <el-button class="border-0" size="mini" type="plain">
        <i class="el-icon-office-building icon"></i> <b>Office</b> <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link :to="{ name: 'module->Office->Insurance->list->page', params: { listPage: 1}}">
            <i class="el-icon-circle-check"></i>
            Insurance
          </router-link>
        </el-dropdown-item>

        <el-dropdown-item divided>
          <router-link :to="{ name: 'module->Office->Software->list->page', params: { listPage: 1}}">
            <i class="el-icon-cpu"></i>
            Software
          </router-link>
        </el-dropdown-item>

        <el-dropdown-item divided>
          <router-link :to="{ name: 'module->Office->Supplier->list->page', params: { listPage: 1}}">
            <i class="el-icon-user-solid"></i>
            Suppliers
          </router-link>
        </el-dropdown-item>

        <el-dropdown-item divided>
          <router-link :to="{ name: 'module->Office->Inventory->list->page', params: { listPage: 1}}">
            <i class="el-icon-box"></i>
            Inventory
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!--<el-menu-item @click="menuOpenToggle" class="icon-container float-left">
      <i class="el-icon-arrow-left icon"></i>
    </el-menu-item>-->

    <!-- <el-menu-item @click="openPage('/cpanel/user/active/profile')" class="icon-container float-left">
       <i class="el-icon-user-solid icon"></i>Profile
     </el-menu-item>

     <el-menu-item @click="openPage('/cpanel/dashboard')" class="icon-container float-left">
       <i class="el-icon-menu icon"></i>Dashboard
     </el-menu-item>


     <el-menu-item  class="icon-container" @click="openInfoDialog">
       <i class="el-icon-info icon"></i>Info
     </el-menu-item>
     <el-menu-item  class="icon-container" @click="openNotificationsDialogVisibility" v-if="newNotificationsCount == 0">
       <i class="el-icon-bell icon"></i>{{newNotificationsCount}} Notifications
     </el-menu-item>
     <el-menu-item  class="icon-container bg-light-gray" @click="openNotificationsDialogVisibility" v-else>
       <i class="el-icon-bell icon"></i>{{newNotificationsCount}} Notifications
     </el-menu-item>-->

    <!--<el-menu-item  class="icon-container bg-light-gray" @click="openPage('/cpanel/Semester/list/1')">
      <i class="el-icon-c-scale-to-original icon"></i>{{currentActiveSemesterName}} Semester
    </el-menu-item>-->

    <!--<el-menu-item  class="icon-container bg-light-gray" @click="showCalenderDialog = true">
      <i class="el-icon-date icon"></i> Calender
    </el-menu-item>-->

    <!--<fullCalenderDialog :visible.sync="showCalenderDialog" :selectEventMode="false" :createEventMode="true" :onEventSelected="false"></fullCalenderDialog>-->

    <el-dialog :visible.sync="notificationsDialogVisibility" title="Notifications" top="5vh" width="800px">
      <el-button @click="clearAllUserNotification" size="small" style="position: absolute;right: 20px;top: 20px;"
                 type="danger">Clear All
      </el-button>
      <div class="p-3">
        <el-table
            :data="userNotifications"
            :row-class-name="tableRowClassName"
            height="500px"
            style="width: 100%">
          <el-table-column
              label="Title"
              prop="title"
              width="150">
          </el-table-column>
          <el-table-column
              label="Message"
              prop="message">
            <template slot-scope="scope">
              <span v-html="scope.row.message"></span>
            </template>
          </el-table-column>
          <el-table-column
              label="Date"
              prop="date"
              width="130">
          </el-table-column>
          <el-table-column
              label="Action"
              prop=""
              width="100">
            <template slot-scope="scope">
              <el-button @click="clearNotification(scope, scope.row.id)" size="small" type="danger">Clear</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="infoDialogVisibility" title="General Information" top="5vh" width="800px">
      <div class="p-3">
        <el-table
            :data="generalInfoData"
            style="width: 100%">
          <el-table-column
              label="Info"
              prop="abber"
              width="180">
          </el-table-column>
          <el-table-column
              label="Description"
              prop="desc">
            <template slot-scope="scope">
              <span v-html="scope.row.desc"></span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </el-menu>
</template>

<script>
import {getLS, strCFL} from "devegram-javascript-util";

export default {
  components: {
    'report-print-form': () => import('@/modules/Accounting/Report/report-print-form/View')
  },
  computed: {
    /*...$mapState('Education-Semester-api', ['currentActiveSemesterId', 'currentActiveSemesterName']),*/
  },
  data() {
    return {
      userDisplayName: $xBundle['CURRENT_USER_PROFILE'].display_name,
      reportTitle: '',
      dueDateLabel: '',
      reportColumns: '',
      reportData: '',
      clientsReportDialog: false,
      isAdmin: $xBundle['CURRENT_USER_PROFILE'].profile_id === 'ADMIN',
      isBookkeeper: $xBundle['CURRENT_USER_PROFILE'].profile_id === 'BOOKKEEPER',
      isPayroll: $xBundle['CURRENT_USER_PROFILE'].profile_id === 'PAYROLL',
      showCalenderDialog: false,
      newNotificationsCount: 0,
      userNotifications: [],
      generalInfoData: [{
        abber: 'INFO',
        desc: 'To determine later',
      }, {
        abber: 'INFO',
        desc: 'To determine later.',
      }, {
        abber: 'INFO',
        desc: 'To determine later.',
      }],
      notificationsDialogVisibility: false,
      infoDialogVisibility: false,
      activeIndex: '1',
      activeIndex2: '1',
      currentDomain: process.env.VUE_APP_CURRENT_DOMAIN,
      userProfileId: $store.state['App-User-state'].profile.profile_id,
      username: $store.state['App-User-state'].profile.username,
      sideMenuRef: null
    };
  },
  mounted() {
    this.sideMenuRef = document.getElementById('el-aside-menu')

    /*this.getUserNewNotificationsCount()
    setInterval(() => {
      this.getUserNewNotificationsCount()
    }, 1000 * 15)*/

    /*$store.dispatch('Education-Semester-api/getCurrentActiveSemester').then( r => {})*/
  },
  methods: {
    openUserClientsReport () {
      this.reportTitle = ((this.isBookkeeper)? 'BOOKKEEPER' : 'PAYROLL') + " "  + this.userDisplayName;

      let date = new Date();
      let currentYear = date.getFullYear();
      let currentMonthName = new Date(currentYear, date.getMonth(), 1).toLocaleString('default', {month: 'short'});
      this.dueDateLabel = "Month of: " + currentMonthName + ", " + currentYear
      this.reportColumns = [
        ...$services['Accounting-Report'].getReportColumns(['business_name', 'business_number', 'contact_name', 'full_work_phone', 'comment', 'fees_paid', 'done'])
      ]
      this.reportData = []

      let apiData = {'province_of_operation_id': '-all-'}
      if (this.isBookkeeper) {
        apiData.account_bookkeeper_user_id = $xBundle['CURRENT_USER_PROFILE'].id
      } else {
        apiData.account_payroll_user_id = $xBundle['CURRENT_USER_PROFILE'].id
      }

      $store.dispatch('Accounting-Report-api/controlReports', {
        report: (this.isBookkeeper)? 'controlReportBookkeeper' : 'controlReportPayroll',
        data: apiData
      }).then(r => {
        console.log('rdata', r)
        this.reportData = r
        this.clientsReportDialog = true
      })
    },
    reportCellCustomClass(cell, data) {
      if (cell.prop == 'business_name') {
        if (data.tax_hst_priority_id == '2' && this.withHighPriority) {
          return 'bg-warning'
        }
      }

      return '';
    },
    goToQuickNote () {
      $router.push({name: 'module->Accounting->Client->list->page', params: {listPage: 1}})
    },
    goToQuickView () {
      $router.push({name: 'module->Accounting->Client->quick-view'})
    },
    openPage(route) {
      $router.push(route)
    },
    clearAllUserNotification() {
      this.$store.dispatch('user-api/clearAllUserNotification', {user_id: $store.state['App-User-state'].profile.id}).then(r => {
        this.notificationsDialogVisibility = false
      })
    },
    getUserNewNotificationsCount() {
      this.$store.dispatch('App-User-api/getUserNewNotificationsCount', {user_id: $store.state['App-User-state'].profile.id}).then(r => {
        this.newNotificationsCount = r
      })
    },
    clearNotification(scope, notificationId) {
      this.userNotifications.splice(scope.$index, 1)
      this.$store.dispatch('user-api/removeNotification', {id: notificationId}).then(r => {
      })
    },
    getUserNotifications() {
      this.$store.dispatch('user-api/getNotifications', {user_id: $store.state['App-User-state'].profile.id}).then(r => {
        this.userNotifications = r
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (row.seen_status == 0) {
        return 'app-bg-gold-low-alfa';
      }
      return '';
    },
    openInfoDialog() {
      this.infoDialogVisibility = true
    },
    openNotificationsDialogVisibility() {
      this.getUserNotifications();
      this.notificationsDialogVisibility = true
    },
    strCFL(s) {
      if (!s) return s;
      return strCFL(s)
    },
    getSessionData(key) {
      return getLS('user')[key]
    },
    drawerOpenToggle() {
      $store.state['app-state'].isDrawerOpen = !$store.state['app-state'].isDrawerOpen
    },
    menuOpenToggle() {
      //$store.state['app-state'].isDrawerOpen = !$store.state['app-state'].isDrawerOpen
      let w = this.sideMenuRef.style.width
      if (w === '0px') {
        this.sideMenuRef.style.width = '200px'
      } else {
        this.sideMenuRef.style.width = '0px'
      }
    },
    handleSelect(key, keyPath) {
    }
  }
}
</script>

<style lang="scss">
.esb-header {
  /*background: linear-gradient(90deg, rgba(160,117,231,1) 0%, rgba(106,44,191,1) 35%, rgba(39,33,128,1) 100%);*/
  background-color: #fff;

  .el-menu-item.is-active {
    color: #fff !important;
    background-color: #000621 !important
  }

  .el-menu-item, .el-submenu {
    /*float: right !important;*/
  }

  .el-menu-item {
    /*text-align: right !important;*/
  }

}

.el-menu--horizontal > .el-menu-item {
  height: 48px !important;
  line-height: 50px;
}

.el-menu.el-menu--horizontal {
  /*border: 1px solid #4e555b !important;*/
  height: 30px;
  /*box-shadow: 1px 0 2px rgba(50,50,50,.8);*/
}

.el-menu-item a {
  color: #fff !important;
  text-decoration: none !important;
}

.el-dropdown-menu__item {
  padding: 0 !important;
  cursor: pointer !important;
}

.el-dropdown-menu__item a {
  padding: 0 20px;
  width: 100%;
  display: block;
  border-top: 1px solid #EBEEF5;
  cursor: pointer !important;
}
</style>
