<template>
  <div v-if="hasMenu()">
    <div style="margin: 0px 0; border-top: 1px solid rgba(255,255,255, 0.2);width: 100%;"
         v-if="hasTopSeparator()"></div>
    <router-link
        :to="{name: module.info.routeName + '->' + module.sideMenu.routeName,  params: module.sideMenu.routeParams}"
        v-if="!hasSubLinks()">
      <el-menu-item :index="module.sideMenu.caption + '-' + module.sideMenu.caption">
        <i :class="module.sideMenu.icon" style="color: #fff;font-size: 12px"></i>
        <span style="font-size: 12px">{{ module.sideMenu.caption }}</span>
      </el-menu-item>
    </router-link>

    <el-submenu :index="module.sideMenu.caption" v-else>
      <template slot="title">
        <i :class="module.sideMenu.icon" style="color: #fff;font-size: 12px"></i>
        <span class="title" style="font-size: 12px">{{ module.sideMenu.caption }}</span>
      </template>

      <router-link :key="subMenuKey"
                   :to="{name: module.info.routeName + '->' + subMenu.routeName, params: subMenu.routeParams}"
                   v-for="(subMenu, subMenuKey) in menuChildren">
        <el-menu-item :index="subMenu.caption + '-' + module.sideMenu.caption" v-if="isSubMenuAllowed(subMenu)">
          <i :class="subMenu.icon" style="color: #fff;font-size:12px"></i>
          <span slot="title" style="font-size: 12px">{{ subMenu.caption }}</span>
        </el-menu-item>
      </router-link>
    </el-submenu>
  </div>
</template>

<script>
export default {
  props: {
    module: {
      required: true
    },
    userProfileId: {
      required: true
    },
    groups: {
      required: true
    }
  },
  data() {
    return {
      menuChildren: []
    }
  },
  mounted() {
    let userProfile = $store.state['App-User-state'].profile.profile_id
    let groupSetting = {group: '', name: ''}
    if (this.module.info.hasOwnProperty('viewGroup')) {
      if (this.groups.hasOwnProperty(this.module.info.viewGroup) && this.groups[this.module.info.viewGroup].hasOwnProperty(userProfile)) {
        groupSetting = {
          group: this.module.info.viewGroup,
          name: this.groups[this.module.info.viewGroup][userProfile].name
        }
      } else {
        // console.log('this.module.info.viewGroup', this.module.info)
      }
    }

    let moduleGroup = groupSetting.group
    let moduleGroupName = groupSetting.name

    let mainContainer = document.getElementById('side-menu-modules-container')
    let groupId = 'side-menu-view-group-' + moduleGroup + '-children'

    let groupContainer = document.getElementById(groupId)
    if (!groupContainer) {
      //let moduleGroupName = this.module.info.viewGroup[$store.state['App-User-state'].profile.profile_id] || 'default'
      groupContainer = document.createElement('div')
      groupContainer.setAttribute('id', groupId)
      groupContainer.setAttribute('class', 'side-menu-view')
      groupContainer.style.cssText = ''
      groupContainer.innerHTML = "<div class='text-white m-2'>" + moduleGroupName + "</div>"
      mainContainer.appendChild(groupContainer)
    }
    groupContainer.appendChild(this.$el)
  },
  methods: {
    isSubMenuAllowed(subMenu) {
      if (subMenu.hasOwnProperty('notAllowedProfiles')) return !subMenu.notAllowedProfiles.includes(this.userProfileId)
      return true
    },
    linkClicked() {
      this.$store.state['side-nav-state'].isCollapsed = true
    },
    hasSubLinks() {
      if (this.module.sideMenu.hasOwnProperty('children')) {
        this.menuChildren = this.module.sideMenu.children
        return true
      }
      return false
    },
    hasMenu() {
      let c1 = this.module.hasOwnProperty('sideMenu');
      if (c1 && this.module.sideMenu.hasOwnProperty('notAllowedProfiles')) {
        return !this.module.sideMenu.notAllowedProfiles.includes(this.userProfileId)
      }

      return c1;
    },
    hasTopSeparator() {
      return this.module.sideMenu.hasOwnProperty('topSeparator') && this.module.sideMenu.topSeparator;
    },
  }
}
</script>
