<template>
  <el-container class="esb-container">
    <esbSidenav v-if="$services['user'].isRoot()"></esbSidenav>
    <el-container id="content-main-container" class="app-main-bg" style="height: 100vh; overflow-y: auto;">
      <el-header class="p-0 m-0 w-100" style="height: 30px">
        <el-col  style="width: calc(100%);float: right">
          <esb-header></esb-header>
        </el-col>
        <!--<esb-breadcrumb></esb-breadcrumb>-->
      </el-header>

      <el-col :span="24">
        <el-main style="padding: 0px !important; padding-top: 0px !important; margin: 0 !important;">
          <div style="display: flex;align-items: center;justify-content: center;padding: 0px !important;margin: 0 !important">
            <transition name="slide-fade" class="h-100">
              <router-view :key="$route.fullPath"></router-view>
            </transition>
          </div>
        </el-main>
      </el-col>
    </el-container>
    <!--<el-col :span="24">
        <esb-breadcrumb></esb-breadcrumb>
    </el-col>-->
  </el-container>
</template>

<script>
/*import esbBreadcrumb from './layout/breadcrumb/View'
$vue.component('esb-breadcrumb', esbBreadcrumb);*/

  import '@/modules-config/css/style.css'
  import '@/../public/portals/cpanel/style.css'
  import '@/../public/portals/cpanel/bootstrap5.beta3.css'

  import "devegram-vue-data-collecter-eui/src/css/style.css"
  import "devegram-vue-data-collecter-eui/src/export.js"

  import ElementUI from 'element-ui';
  import 'element-ui/lib/theme-chalk/index.css';
  //import 'element-ui/lib/theme-chalk/display.css';
  import lang from 'element-ui/lib/locale/lang/en'
  import locale from 'element-ui/lib/locale'
  locale.use(lang)
  $vue.use(ElementUI);


  import Paginate from 'vuejs-paginate'
  import '@/components/export'
  $vue.component('paginate', Paginate)


  import esbSidenav from './layout/sidenav/View'
  import esbHeader from './layout/header/View'
  export default {
    components: {
      esbSidenav,
      esbHeader
    },
    data () {
      return {
        controller: this
      }
    },
    beforeMount() {
      if ($store.getters['App-User-state/isAuthenticated']) {
        let links = document.querySelectorAll('[portal]');

        links.forEach( (l) => {
          if (l.getAttribute('portal') == 'portal-home') {
            l.parentNode.removeChild(l)
          }
        })
      }
    },
    mounted() {
      let styles = [
        'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'
      ]

      for (let x in styles) {
        let element = document.createElement("link");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        element.setAttribute("portal", "portal-cpanel");
        element.setAttribute("href", styles[x]);
        document.getElementsByTagName("head")[0].appendChild(element);
      }
    }
  };
</script>

<style scoped>
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.5, 0.5, 0.8, 0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

<!--<style scoped>
  @import "/portals/cpanel/style.css";
</style>-->
