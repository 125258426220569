<template>
  <el-aside :class="$langConfig.cssClass" class="esb-sidenav" id="el-aside-menu" style="width: 200px;  scrollbar-width: none;">

    <el-menu
        :collapse="isCollapsed"
        :collapse-transition="true"
        :default-active="$store.state['App-SideMenu-state'].currentActiveSection"
        :default-openeds="currentActiveMenus"
        :router="false"
        :unique-opened="true"
        @close="handleClose"
        @select="handleOpen"
        active-text-color="#151B26"
        background-color="transparent"
        text-color="#fff">


      <div class="mb-4 mt-3 text-center">
        <!--<img src="@/../public/img/logo/logo.png" style="border-radius: 50%;opacity: .4;background-color: #fff;padding:15px;" width="62px"/>-->
        <div class="text-white" style="margin: 10px 0;">
          {{ strCFL(getSessionData('display_name')) }}
        </div>
      </div>

      <!--<div style="position: fixed;bottom: 1px; right: 0; width: fit-content;">
        <img class="d-inline-block" src="@/../public/img/logo/logo.png"
             style="border-radius: 50%; padding: 0px;opacity: .2" width="200px"/>
        <div class="font-weight-bold ml-1 d-inline-block" style="font-size: 8pt; opacity: .2;">
          {{ $store.state['App-App-state'].title }}
          {{ $store.state['App-App-state'].version }}
        </div>
      </div>-->

      <!-- Grouping Modules Together-->
      <div id="side-menu-modules-container">
        <div :key="key" v-for="(group, key) in groups">
          <div :class="group.bgSectionColor" :id="'side-menu-view-group-' + key" class="side-menu-group"
               v-if="group[userProfileId]">
            <div class="sidebar-group-mark"  @click="toggleGroupContainerClass($event, 'hidden-module-group-modules')">
              {{ group[userProfileId].name }} <i class="el-icon-arrow-right" style="position: absolute;right: 20px;top: 12px;"></i>
            </div>
            <div :id="'side-menu-view-group-' + key + '-children'" class="side-menu-view hidden-module-group-modules"></div>
          </div>
        </div>
      </div>

      <!-- User Modules -->
      <div :key="namespaceKey" class="w-100" v-for="(namespace, namespaceKey) in $hkms.modules">
        <MenuLink :key="moduleKey" :module="$hkms.modules[namespaceKey][moduleKey]" :groups="groups"
                  v-for="(module, moduleKey) in $hkms.modules[namespaceKey]" :userProfileId="parseInt(userProfileId)">
        </MenuLink>
      </div>

    </el-menu>
  </el-aside>
</template>

<script>

import {getLS, strCFL} from 'devegram-javascript-util';
import MenuLink from './MenuLink';

export default {
  components: {
    MenuLink: MenuLink
  },
  data() {
    return {
      groups: {
        'system': {
          'ROOT': {
            name: 'System'
          },
          bgSectionColor: ''
        },
        'business-modules': {
          'ROOT': {
            name: 'Business Modules'
          },
          bgSectionColor: ''
        },
        'Accounting': {
          'ROOT': {
            name: 'Accounting'
          },
          bgSectionColor: ''
        },
        'Person': {
          '1': {
            name: 'Person'
          },
          bgSectionColor: ''
        },
        'Enrollment': {
          '1': {
            name: 'Enrollment'
          },
          bgSectionColor: ''
        },
        'Education': {
          '1': {
            name: 'Education'
          },
          bgSectionColor: ''
        },
        'Institute': {
          '1': {
            name: 'Institute'
          },
          bgSectionColor: ''
        },
        'TeachingInstrument': {
          '1': {
            name: 'Teaching Instruments'
          },
          bgSectionColor: ''
        }
      },
      userProfileId: $store.state['App-User-state'].profile.profile_id,
      drawerOpen: true,
      tempModule: null,
      tempModuleSideMenu: null,
      tempModuleSideMenuChildren: [],
      isCollapsed: this.$store.state['App-SideMenu-state'].isCollapsed,
      currentActiveMenus: Object.values(this.$store.state['App-SideMenu-state'].activeSubMenus),
      hasTestingPanelModule: this.$store.hasModule('voip-testing-panel-api'),
      divisionsList: []
    }
  },
  beforeMount() {},
  methods: {
    toggleGroupContainerClass (e, className) {
      e.target.childNodes[1].classList.toggle('el-icon-arrow-right')
      e.target.childNodes[1].classList.toggle('el-icon-arrow-down')
      e.target.parentNode.childNodes[1].classList.toggle(className)
    },
    linkClicked() {
      this.$store.state['App-SideMenu-state'].isCollapsed = true
    },
    /*...$mapActions('voip-rate-division-api', {getDivisionsDropDownList: 'getDropDownList'}),*/
    handleDrawerClose() {
      $store.state['App-App-state'].isDrawerOpen = false
    },
    moduleHasChildren(moduleName) {
      this.tempModule = null
      this.tempModuleSideMenu = null
      if (this.$hkms.modules.hasOwnProperty(moduleName)) {
        this.tempModule = this.$hkms.modules[moduleName]
        //this.tempModuleSideMenu = this.tempModule.sideMenu
        if (this.tempModuleSideMenu) {
          if (this.tempModuleSideMenu.hasOwnProperty('children')) {
            this.tempModuleSideMenuChildren = this.tempModuleSideMenu.children
            return true
          }
        }
      }
      return false
    },
    strCFL(s) {
      if (!s) return s;
      return strCFL(s)
    },
    getSessionData(key) {
      return getLS('user')[key]
    },
    handleOpen(key, keyPath) {
      this.$store.dispatch('App-SideMenu-state/updateActiveMenus', {
        key: keyPath[0],
        action: 'add'
      });
      this.$store.state['App-SideMenu-state'].currentActiveSection = key;
    },
    handleClose(key, keyPath) {
      this.$store.dispatch('App-SideMenu-state/updateActiveMenus', {
        key: keyPath[0],
        action: 'remove'
      })
    }
  },
  computed: {
    ...$mapState('App-User-state', ['modules']),
    collapseIcon() {
      this.$store.dispatch('sideNavStore/updateCollapsed', {
        isCollapsed: this.isCollapsed
      });
      if (this.isCollapsed) {
        return 'el-icon-d-arrow-right';
      } else {
        return 'el-icon-d-arrow-left';
      }
    }
  },
  mounted() {
    document.getElementById('el-aside-menu').style.width = '200px'
  }
}
</script>

<style lang="scss">
/*background: linear-gradient(110deg, rgba(106,28,232,1) 0%, rgba(64,36,167,1) 35%, rgba(39,33,128,1) 100%);*/
.el-menu {
  border-right: none !important;
}

.router-link-exact-active {
}

.esb-sidenav {
  font-size: 11px;
  border: none !important;
  overflow-x: hidden;
  /*width: 230px !important;*/
  transition: width .3s ease-in-out;
  height: 100vh !important;
  background-color: #031b4d;
  z-index: 10;

  .user_brief {
    .info {
    }
  }
}

.el-submenu .is-opened {
  width: 100% !important;
}

.esb-sidenav {
  a.router-link-exact-active {
    text-decoration: none !important;

    .el-menu-item i {
      /*color: #151B26 !important;*/
    }
  }
}

.esb-sidenav a.router-link-exact-active .el-menu-item {
  /*color: #151B26 !important;
  text-decoration: none !important;
  background-color: #F2F2F0 !important;*/
}

.el-submenu__title .el-icon-arrow-down {
  padding-top: 5px;
  transform: scale(1.2);
  color: #fff !important;
}

.esb-sidenav a:active {
  color: transparent;
}

.el-menu--collapse {
  .avatar-pic {
    .info {
      display: none;
    }
  }
}

.el-menu .el-menu--inline {
  background-color: #1d3752 !important;
}

.esb-sidenav .el-menu-item {
  height: 32px;
  line-height: 28px;
}

.esb-sidenav div a li.el-menu-item {
  padding-left: 5px !important;
  margin-top: 6px;
}

.esb-sidenav .el-submenu__title {
  padding: 5px !important;
  height: 40px;
  line-height: 28px;
  color: #F2F2F0;
  /*background-color: rgba(20,20,20, .5) !important;*/
}

.esb-sidenav .el-menu-item.is-active::before {
  content: "";
  width: 1px;
  height: 0px;
  position: absolute;
  left: 0px;
  display: block;
  color: #C4D4F2 !important;
  background-color: #F2F2F0;
  box-shadow: 0px 0px 2px #F2F2F0;
  transition: box-shadow .4s ease-in-out;
}

.esb-sidenav .el-menu-item.is-active {
  width: 1100% !important;
  background-color: rgba(167, 184, 217, 0.2) !important;
  box-shadow: 0px 0px 2px #F2F2F0;
  transition: box-shadow .4s ease-in-out;
}

.esb-sidenav .el-submenu__title:hover, .esb-sidenav .el-menu-item {
  width: 1100% !important;
}

.esb-sidenav .el-submenu__title:hover, .esb-sidenav .el-menu-item:hover {
  background-color: rgba(167, 184, 217, 0.2) !important;
  width: 1100% !important;
  color: #fff !important;
}

.esb-sidenav .el-submenu__title:hover i {
  /*color: #151B26;*/
}


/* rtl */
.esb-sidenav.rtl {
  overflow-x: hidden;

  .el-submenu, .el-menu-item {
    /*text-align: right;
    position: relative;
    left: 50px;*/
  }

  .is-opened .el-submenu__title {
    width: 100%;
    /*background-color: #060606 !important;
    color: #fff !important;
    width: 200%;
    margin-bottom: 12px;*/
  }
}

.el-submenu.is-opened {background-color: transparent !important;}
.esb-sidenav .el-menu-item {background-color: transparent !important;}
.esb-sidenav .el-menu-item.is-active {
  color: #fff !important;
  font-weight: bold;
  /*background-color: #464646 !important;
  box-shadow: 0 0px 2px #fff, 0 0px 2px #fff;
  width: 200% !important;*/
}

/*.esb-sidenav .el-submenu__title i {
    color: #F2F2F0;
}*/

.esb-sidenav .side-menu-group {
  /*font-family: 'Open Sans', sans-serif !important;*/
  /*font-size: 9pt !important;*/
  /*border-top: 1px solid rgba(255,255,255, 0.2);*/
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #221e1e;
  width: 100%;
}

.sidebar-group-icon {
  color: #fff;
  font-size: 9pt;
}


.side-menu-view {
  position: relative;
  opacity: 1;
  left: 0px;
  display: block;
  z-index: 1;
  transition: left .2s ease-in-out;
}

.hidden-module-group-modules {
  position: absolute;
  left: -200px;
  opacity: 0;
  transition: z-index .4s ease-out, opacity .1s ease-out, left .2s ease-in-out;
  z-index: -9;
  visibility: hidden;
}

.sidebar-group-mark {
  position: relative;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  display: inline-block;
  color: #fff;
  font-size: 9pt;
  background-color: rgba( 122, 56, 160 , 0.2) !important;
  padding: 8px;
  transition: max-height .3s ease-in-out;
  visibility: visible;
}

.module-icon {
  font-size: 9pt !important;
  color: #fff !important;
}

.module-label {
  font-size: 9pt !important;
}

.el-submenu__icon-arrow {
  top: 40%;
  z-index: 9000 !important;
}

</style>
